import React, { useCallback } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Table from 'react-bootstrap/Table';
import {
  ArrowDownShort,
  ArrowUpShort,
  X,
  XCircle,
} from 'react-bootstrap-icons';
import TooltipOverlay from '../TooltipOverlay';
import { Alert } from 'react-bootstrap';

const Results = ({
  data,
  draft,
  marking,
  order,
  onMark = () => {},
  search,
  sort,
}) => {
  const arrow = order === '-' ? <ArrowDownShort /> : <ArrowUpShort />;

  const getEntryClass = useCallback(
    row => {
      const markingAndDraft = [...(marking ?? []), ...(draft ?? [])];
      const markingAndDraftSorted = (markingAndDraft ?? []).sort(
        (a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0),
      );
      const rowMarking = (markingAndDraftSorted ?? []).filter(
        mark => mark.subId === row.sub_id,
      )[0];
      if (!rowMarking) return '';
      else if (!!rowMarking.suspicious) return 'suspicious';
      else return '';
    },
    [draft, marking],
  );

  const TableHeader = ({ field, label, sortable = false }) => {
    let newOrder = order;

    const onClick = () => {
      if (sort === field) newOrder = order === '-' ? '' : '-';
      search(field.replaceAll('.', '_'), newOrder, []);
    };

    return (
      <th>
        {sortable ? (
          <Button
            variant="link"
            className="p-0 text-light w-100"
            onClick={onClick}
          >
            {label} {sort === field && arrow}
          </Button>
        ) : (
          label
        )}
      </th>
    );
  };

  const callMark = (valid, row) => {
    onMark({
      amount: row.contribution_receipt_amount,
      createdAt: Date.now(),
      imageNumber: row.image_number,
      state: row.contributor_state,
      subId: row.sub_id,
      suspicious: true,
      zip: row.contributor_zip,
    });
  };

  return (
    <>
      {!!data && !data?.results?.length && (
        <Alert variant="light" className="mt-3 w-100">
          No donations reported using that name
        </Alert>
      )}

      {!!data?.results?.length && (
        <Table
          variant="dark"
          className="text-nowrap text-start"
          bordered
          hover
          responsive
          striped
        >
          <thead>
            <tr>
              <th>Suspicious</th>

              <th>
                <TableHeader
                  field="contribution_receipt_date"
                  label="Date"
                  sortable
                />
              </th>

              <th>
                <TableHeader
                  field="contribution_receipt_amount"
                  label="Amount"
                  sortable
                />
              </th>

              <th>
                <TableHeader field="committee.name" label="Recipient" />
              </th>

              <th>
                <TableHeader field="contributor_name" label="Name" />
              </th>

              <th>
                <TableHeader
                  field="contributor_occupation"
                  label="Occupation"
                />
              </th>

              <th>
                <TableHeader field="contributor_city" label="City" />
              </th>

              <th>
                <TableHeader field="contributor_state" label="State" />
              </th>

              <th>
                <TableHeader field="contributor_zip" label="Zip" />
              </th>

              <th>
                <TableHeader field="contributor_street_1" label="Address" />
              </th>
            </tr>
          </thead>
          <tbody>
            {data.results.map(row => (
              <>
                <tr className={getEntryClass(row)}>
                  <td className="text-center">
                    <ButtonGroup>
                      <TooltipOverlay text="This is suspicious">
                        <Button
                          variant="dark"
                          className="text-danger"
                          onClick={() => callMark(false, row)}
                        >
                          {getEntryClass(row) === 'suspicious' ? (
                            <XCircle />
                          ) : (
                            <X />
                          )}
                        </Button>
                      </TooltipOverlay>
                    </ButtonGroup>
                  </td>

                  <td>{row.contribution_receipt_date}</td>
                  <td>${row.contribution_receipt_amount.toLocaleString()}</td>
                  <td>{row.committee.name}</td>
                  <td>{row.contributor_name}</td>
                  <td>{row.contributor_occupation}</td>
                  <td>{row.contributor_city}</td>
                  <td>{row.contributor_state}</td>
                  <td>{row.contributor_zip}</td>
                  <td>
                    {[row.contributor_street_1, row.contributor_street_2].join(
                      ' ',
                    )}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Results;
