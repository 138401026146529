import React, { useState } from 'react';
import Alert from 'react-bootstrap/esm/Alert';
import Button from 'react-bootstrap/esm/Button';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import StateDropdown from '../StateDropdown';
import { Col, Row } from 'react-bootstrap';

const Searchbar = ({
  city,
  name,
  search,
  setCity,
  setName,
  setPriorIndex,
  setState,
  setZip,
  setYear,
  state,
  year,
  zip,
}) => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [error, setError] = useState(null);

  const onKeyUp = e => {
    if (e.key.toUpperCase() === 'ENTER') onSearch();
  };

  const onSearch = () => {
    if (!name) setError('Please, enter a name');
    else {
      setError(null);
      setPriorIndex([]);
      search();
    }
  };

  return (
    <>
      <InputGroup>
        <FloatingLabel label="Name">
          <Form.Control
            value={name ?? ''}
            onChange={e => setName(e.target.value)}
            onKeyUp={onKeyUp}
          />
        </FloatingLabel>
        <Button variant="light" onClick={() => setShowAdvanced(!showAdvanced)}>
          Advanced
        </Button>
        <Button disabled={!name} onClick={onSearch}>
          Search
        </Button>
      </InputGroup>

      {showAdvanced && (
        <Row className="mt-3">
          <Col md={2} className="mb-3 mb-md-0">
            <FloatingLabel label="City (Optional)">
              <Form.Control
                value={city ?? ''}
                onChange={e => setCity(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col md={2} className="mb-3 mb-md-0">
            <StateDropdown
              label="State (Optional)"
              value={state}
              onChange={e => setState(e.target.value)}
            />
          </Col>
          <Col md={2} className="mb-3 mb-md-0">
            <FloatingLabel label="Zip  (Optional)">
              <Form.Control
                value={zip ?? ''}
                onChange={e => setZip(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col md={2}>
            <FloatingLabel label="Year  (Optional)">
              <Form.Select
                value={year ?? ''}
                onChange={e => setYear(e.target.value)}
              >
                <option value={''}>-- Select a year</option>

                {'0'
                  .repeat(100)
                  .split('')
                  .map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return <option value={year}>{year}</option>;
                  })}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
      )}

      {!!error && (
        <Alert variant="danger" className="mt-3 w-100">
          {error}
        </Alert>
      )}
    </>
  );
};

export default Searchbar;
