import React from 'react';
import { Accordion, ListGroup } from 'react-bootstrap';

const FAQ = () => (
  <>
    <p className="fs-3 fw-bold text-white">FAQ</p>
    <Accordion variant="dark" className="mt-3" flush alwaysOpen={false}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className="fs-4">What is CheckMyDonation.org?</div>
        </Accordion.Header>
        <Accordion.Body>
          <p className="fs-4">
            CheckMyDonation.org provides a free online tool to identify victims
            of political donation identity theft (so called “straw donor”
            victims). The Platform enables the following:
          </p>
          <ListGroup as="ol" variant="flush" numbered>
            <ListGroup.Item as="li" className="fs-4">
              You can search and view Federal Election Commission (FEC) donor
              records,
            </ListGroup.Item>
            <ListGroup.Item as="li" className="fs-4">
              You can check the reported donations made in your name and flag
              suspsicious transactions.
            </ListGroup.Item>
            <ListGroup.Item as="li" className="fs-4">
              You can optionally request that your information be provided to
              law enforcement,
            </ListGroup.Item>
            <ListGroup.Item as="li" className="fs-4">
              You can share CheckMyDonation with others and/or donate to
              financially support CheckMyDonation’s mission.
            </ListGroup.Item>
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <div className="fs-4">How do I use CheckMyDonation.org?</div>
        </Accordion.Header>
        <Accordion.Body>
          <ListGroup as="ol" variant="flush" numbered>
            <ListGroup.Item as="li" className="fs-4">
              Search for your name to view all political donations reported in
              your name to the Federal Election Commission (FEC).
            </ListGroup.Item>
            <ListGroup.Item as="li" className="fs-4">
              Flag any donation transactions that you don't recognize as
              “suspicious.” We will report these to law enforcement.
            </ListGroup.Item>
            <ListGroup.Item as="li" className="fs-4">
              Many people can share the same or similar names, so be sure to
              check the address or other personal information to confirm you
              only respond to donation transactions made about you (and not
              someone else with the same or similar name).
            </ListGroup.Item>
            <ListGroup.Item as="li" className="fs-4">
              Optionally, you may request that your information be provided to
              law enforcement, share CheckMyDonation with others, and/or donate
              to financially support CheckMyDonation’s mission.
            </ListGroup.Item>
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <div className="fs-4">Where do the search results come from?</div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="fs-4">
            Results come directly from the Federal Election Commission (FEC)
            database, which we access via an API to conveniently display the
            information for you. This is a public dataset provided by the U.S.
            Government.
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <div className="fs-4">
            How do I know if a transaction is suspicious or not?
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="fs-4">
            This is up to your judgment. The purpose of CheckMyDonation.org is
            to identify political donor fraud victims. Generally speaking if you
            recognize or remember making the donation, it is not suspicious
            (even if you no longer support that candidate or committee). But if
            you truly do not recognize the donation or you believe you did not
            make the donation reported in your name, you should flag it as
            suspicious.
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <div className="fs-4">
            Why do I see transactions from other people with my same name?
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="fs-4">
            The FEC database is based on first and last name. Many people share
            the same first and last names, so you may want to use the “Advanced
            Search” features to filter down by location or other factors to
            display only the donations that relate to you.
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <div className="fs-4">
            Should I choose to share my information with law enforcement?
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="fs-4">
            This is up to you. Providing your information will make it easier
            for law enforcement and investigators to reach out to you for
            follow-up. Participating with law enforcement not only helps them
            catch the bad guys, but may also entitle you to any recourse should
            law enforcement make it available.
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <div className="fs-4">
            Why should I share CheckMyDonation with others?
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="fs-4">
            Like us, you probably care about your friends and family, and you
            don't like fraudsters. The more people that use CheckMyDonation, the
            more victims of identity fraud that we can identify and provide to
            law enforcement. This will help current victims get justice, and
            help law enforcement catch the bad guys.
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>
          <div className="fs-4">
            What happens next if I flag a transaction as suspicious?
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="fs-4">
            A transaction flagged as suspicious will be reviewed and forwarded
            to law enforcement. You can optionally choose to provide your
            contact information for law enforcement use as well. If you do not
            provide your contact information, law enforcement will receive the
            information available in the public FEC database.
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>
          <div className="fs-4">
            Who is responsible for CheckMyDonation.org?
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="fs-4">
            CheckMyDonation.org is run by QuantumRed, a company hired by the
            U.S. House of Representatives to help investigate donor identity
            fraud. QuantumRed is not under contract to run this page. It does so
            as a free public service to assist law enforcement.
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="9">
        <Accordion.Header>
          <div className="fs-4">
            What should I support CheckMyDonation financially?
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="fs-4">
            We launched CheckMyDonation at no cost to users, but it costs us to
            keep the site going. The more support we receive, the more people we
            can help. Your support of a few dollars is greatly appreciated.
            Contributions are not tax deductible.
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </>
);

export default FAQ;
