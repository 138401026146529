import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import { Button, ButtonGroup, Container, Image } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShareModal from './components/ShareModal';
import TooltipOverlay from './components/TooltipOverlay';
import Home from './components/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import FAQ from './components/FAQ';
import { updateAnalytics } from './utilities';

function App() {
  const [showShareModal, setShowShareModal] = useState(false);

  const handleShareClick = () => {
    updateAnalytics('shareModalOpen', {});
    setShowShareModal(true);
  };

  const handleSupportClick = () => {
    updateAnalytics('supportClick', {});
    window.open('https://buy.stripe.com/bIY4k89lk3x48CIcMP', '_blank');
  };

  return (
    <Container fluid>
      <ToastContainer />

      <ShareModal
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
      />

      <Row className="mt-2 mt-md-5">
        <Col md={1}></Col>
        <Col md={10}>
          <Row className="alexandria-logo logo mb-5 text-light text-start">
            <Col md={10} className="mb-3 mb-md-0">
              <Image src="logo.png" /> CheckMyDonation.org
            </Col>
            <Col md={2} className="text-end">
              <ButtonGroup className="h-100 w-100">
                <TooltipOverlay text="Share with your friends">
                  <Button
                    variant="secondary h-100 p-3 w-100"
                    onClick={handleShareClick}
                  >
                    Share
                  </Button>
                </TooltipOverlay>
                <TooltipOverlay text="Support the cause">
                  <Button
                    variant="primary h-100 p-3 w-100"
                    onClick={handleSupportClick}
                  >
                    Support
                  </Button>
                </TooltipOverlay>
              </ButtonGroup>
            </Col>
          </Row>

          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="privacypolicy" element={<PrivacyPolicy />} />
                <Route
                  path="termsandconditions"
                  element={<TermsAndConditions />}
                />
              </Route>
            </Routes>
          </BrowserRouter>

          <Row>
            <Col xs={12}>
              <FAQ />
            </Col>
          </Row>

          <Row className="fs-4 mt-5 text-center text-white">
            <Col xs={12}>
              <Button
                variant="link"
                className="text-white"
                href="/termsandconditions"
                target="_blank"
              >
                Terms & Conditions
              </Button>

              <Button
                variant="link"
                className="text-white"
                href="/privacypolicy"
                target="_blank"
              >
                Privacy Policy
              </Button>
            </Col>
          </Row>

          <Row className="fs-5 mt-5 text-white">
            <Col xs={12}>
              CheckMyDonation.org is an independent service offered for free for
              the public good. We work with and will provide lists of victims to
              law enforcement, but we are are not affiliated with or endorsed by
              any specific federal or state law enforcement agency or
              organization.
            </Col>
          </Row>

          <Row className="fs-5 my-5 text-center text-white">
            <Col xs={12}>
              &copy; CheckMyDonation.org, {new Date().getFullYear()}. All rights
              reserved.
            </Col>
          </Row>
        </Col>
        <Col md={1}></Col>
      </Row>
    </Container>
  );
}

export default App;
