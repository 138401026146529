import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import TooltipOverlay from '../TooltipOverlay';

const ShareModal = ({ onHide, show }) => {
  const url = 'https://CheckMyDonation.org';

  return (
    <Modal show={show} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          Protect your friends. Help others check donations reported in their
          names. Share CheckMyDonation.org.
        </div>

        <div className="text-center">
          <TooltipOverlay text="Email">
            <EmailShareButton url={url} className="me-2">
              <EmailIcon size={32} round />
            </EmailShareButton>
          </TooltipOverlay>
          <TooltipOverlay text="X">
            <TwitterShareButton url={url} className="me-2">
              <XIcon size={32} round />
            </TwitterShareButton>
          </TooltipOverlay>
          <TooltipOverlay text="Facebook">
            <FacebookShareButton url={url} className="me-2">
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </TooltipOverlay>
          <TooltipOverlay text="LinkedIn">
            <LinkedinShareButton url={url} className="me-2">
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </TooltipOverlay>
          <TooltipOverlay text="Reddit">
            <RedditShareButton url={url} className="me-2">
              <RedditIcon size={32} round />
            </RedditShareButton>
          </TooltipOverlay>
          <TooltipOverlay text="WhatsApp">
            <WhatsappShareButton url={url} className="me-2">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </TooltipOverlay>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
