import React, { useCallback } from 'react';
import { useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  FloatingLabel,
  Form,
  InputGroup,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export const useSubmitModal = () => {
  const [email, setEmail] = useState(null);
  const [grounds, setGrounds] = useState(null);
  const [name, setName] = useState(null);
  const [notes, setNotes] = useState(null);
  const [onSubmit, setOnSubmit] = useState(null);
  const [phone, setPhone] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleGroundsChange = e => {
    const value = e.target.value;
    setGrounds(!!value && value !== '' ? value : null);
  };

  const handleOK = useCallback(() => {
    setShowModal(false);
    onSubmit({ email, grounds, notes, name, phone });
  }, [email, grounds, name, notes, onSubmit, phone]);

  const SubmitModal = useMemo(
    () => (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Suspicious Entries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            By flagging a record as "suspicious, I certify that I have personal
            knowledge forming a good faith belief that the transaction is
            suspicious. I understand that these reports may be forwarded to law
            enforcement, and that making false claims to law enforcement may
            have legal consequences.
          </div>

          <Form.Select
            className="mb-3"
            value={grounds ?? ''}
            onChange={handleGroundsChange}
          >
            <option value="self">
              I am flagging this donation on behalf of myself
            </option>
            <option value="other">
              I am flagging this donation as suspicious on behalf of someone
              else
            </option>
          </Form.Select>

          <FloatingLabel
            label={`${
              grounds !== 'other' ? 'My Name' : "Other's Name"
            } (Required)`}
          >
            <Form.Control
              className="mb-3"
              type="text"
              value={name ?? ''}
              onChange={e => setName(e.target.value)}
            />
          </FloatingLabel>

          <InputGroup>
            <FloatingLabel label="Email (Optional)">
              <Form.Control
                type="email"
                value={email ?? ''}
                onChange={e => setEmail(e.target.value)}
              />
            </FloatingLabel>
            <FloatingLabel label="Phone  (Optional)">
              <Form.Control
                type="number"
                value={phone ?? ''}
                onChange={e => setPhone(e.target.value)}
              />
            </FloatingLabel>
          </InputGroup>

          <FloatingLabel label="Notes (Optional)">
            <Form.Control
              className="mt-3"
              type="text"
              value={notes ?? ''}
              onChange={e => setNotes(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="light" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button disabled={!grounds || !name} onClick={handleOK}>
              Submit
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    ),
    [email, grounds, handleOK, name, notes, phone, showModal],
  );

  const submit = settings => {
    if (!!settings.email) setEmail(settings.email);
    if (!!settings.phone) setPhone(settings.phone);
    setOnSubmit(() => settings.onSubmit);
    setShowModal(true);
  };

  return { submit, SubmitModal };
};
